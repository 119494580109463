import React, {Component} from 'react';
import Layout from '../components/Shared/Layout';
import Covid19 from '../components/Covid19';
import '../assets/css/privacy.css';
import * as ReactGA from 'react-ga';

class Covid_19 extends Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Covid.Message',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <div>
        <Layout>
          <Covid19 />
        </Layout>
      </div>
    );
  }
}
export default Covid_19;
