import React from 'react';
import {Link} from 'gatsby';
import {Helmet} from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>A Message from our CEO on Covid 19 | Tintash</title>
    </Helmet>
    <div>
      <section className="main-privacy-heading">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 py-3">
              <h1 className="latoBlack text-white heading">Covid-19</h1>
              <p className="text-white latoRegular">
                Given the current global COVID’19 pandemic, it is our responsibility as an
                organisation to keep all of our stakeholders informed about how we’re responding to
                the situation. We have been taking essential precautions to mitigate the impact of
                COVID’19 on our employees, our work and the society at large.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="privacy">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-10">
              <div className="content">
                <h4 className="text-grey latoBlack">The Wellbeing of Our Team: </h4>
                <p>
                  The safety and wellbeing of our team remains our number one priority through these
                  trying times. Our initial response included an on-premise awareness session with
                  the entire team, a training session on essential cleaning/disinfection measures
                  with our support staff, followed by a pilot work from home feasibility experiment
                  with over 70% of our employees. By 23rd March, Tintash had made{' '}
                  <b>working from home mandatory</b> for all its teams and we continue to work as a
                  remote team to date so as to not compromise on the health and safety of our
                  employees.
                </p>
                <h4 className="text-grey latoBlack">
                  Our Plan to Get Through the Financial Crunch:
                </h4>
                <p>
                  We are closely monitoring the impact of COVID’19 on our financial health. Our
                  revenue, bookings, and cash flows remain strong but we are taking precautionary
                  cost-cutting measures and will be in a position to rely on our cash reserves if
                  need be, till the time the global economy bounces back to its original position.
                </p>
                <h4 className="text-grey latoBlack">Our Ability to Power Remote Teams: </h4>
                <p>
                  By the time our team went fully remote, we made sure that employees were equipped
                  enough to work remotely with minimum disruptions. For this purpose, employees are
                  provided with laptops, backup internet and testing devices as necessary. In the
                  past, we’ve successfully worked with distributed teams across the globe (Teams
                  based out of Boston, Columbia, Egypt, London, San Francisco, Singapore, Toronto
                  and the Ukraine) In the current scenario, we’ve been running all our projects
                  remotely for over 2 weeks now and haven’t faced any major productivity lags around
                  deliverables and deadlines.
                </p>
                <h4 className="text-grey latoBlack">Lending a Helping Hand:</h4>
                <p>
                  If you are a company facing challenges in terms of meeting your milestones or
                  development needs due to the current situation, please feel free to reach out. We
                  would be happy to help you navigate through your project requirements in any way
                  we can. We are all in this together :-)
                </p>
                <h4 className="text-grey latoBlack">Getting in Touch With Us:</h4>
                <p>
                  To get in touch with our sales team, please use the{' '}
                  <Link to="/contact">contact form</Link> on our website or drop an email at{' '}
                  <a href="mailto:bd@tintash.com">bd@tintash.com</a>.
                  <br />
                  <br />
                  For any other general enquiries please email us at{' '}
                  <a href="mailto:allears@tintash.com">allears@tintash.com</a> where your query will
                  be redirected to the relevant team member who will get back to you as soon as they
                  can.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);
